import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/login/login.vue'
import LXL from "./liuxiaoli.js"
import router_wq from './wq'
import modifyPsw from "@/views/login/modifyPsw.vue"
import bindPhone from "@/views/login/bindPhone.vue"

import {Layout} from '@/layout'

Vue.use(Router)

export const constantRouterMap = [
	{
	  path: '/',
	  name: 'Login',
	  meta:{
		  isMenu:false,
		  title:'登录'
	  },
	  component: Login
	},
	{
		path: '/modifyPsw',
		name: 'modifyPsw',
		meta:{
			isMenu:false,
			title:'修改密码'
		},
		component: modifyPsw
	  },
	  {
		path: '/bindPhone',
		name: 'bindPhone',
		meta:{
			isMenu:false,
			title:'绑定手机'
		},
		component: bindPhone
	  },
	{
	  path: '/home',
	  component:Layout,
	  children: [{
	  		path: '',
	  		meta: {
				isMenu:true,
	  			title: '首页',
	  		},
	  		component: () => import('@/views/home/home.vue')
	  	},
	  	
	  ]
	},
	{
	  path: '/agentHome',
	  component:Layout,
	  children: [{
	  		path: '',
	  		meta: {
				isMenu:true,
	  			title: '欢迎使用',
	  		},
	  		component: () => import('@/views/home/welcome.vue')
	  	},
	  	
	  ]
	},
	{
		path: '/agentHome',
		  component:Layout,
		 children:[
				 {
					 path:"moreStatics",
					 meta:{
						 isMenu:true,
							 title: '更多统计',
					 },
					component: () => import('@/views/home/welcomeMoreSatics.vue') 
				 }
			 ]
	  },
	{
		path: '/payWechart',
		component:Layout,
		children: [{
				path: '',
				meta: {
				  	isMenu:false,
					title: '收款微信',
				},
				component: () => import('@/views/home/payWechart.vue')
			},
			
		]
	  },
	{
	  path: '/card',
	  component:Layout,
	  children: [{
	  		path: 'detail',
	  		meta: {
				isMenu:false,
	  			title: '基本信息',
	  		},
	  		component: () => import('@/views/card/cardDetail.vue')
	  	},
	  	
	  ]
	},
	{
	  path: '/chargeGroup',
	  component:Layout,
	  children: [{
	  		path: 'statistic',
	  		meta: {
				isMenu:false,
	  			title: '计费组统计',
	  		},
	  		component: () => import('@/views/group/groupStatistic.vue')
	  	},
	  	
	  ]
	},
	{
        path: '/orderManagement',
        component:Layout,
        children: [{
                path: 'orderDetail',
                meta: {
                  isMenu:false,
                 title: '订单详情',
                },
                component: () => import('@/views/orderManagement/orderDetail.vue')
            },
            
        ]
	  },
	  {
        path: '/orderManagement',
        component:Layout,
        children: [{
                path: 'agentProfitDetail',
                meta: {
                  isMenu:false,
                 title: '返利详情',
                },
                component: () => import('@/views/orderManagement/agentProfitDetail.vue')
            },
            
        ]
	  },
	//   {
    //     path: '/balanceManagement',
    //     component:Layout,
    //     children: [{
    //             path: 'balanceChangeRecord',
    //             meta: {
    //              isMenu:false,
    //              title: '余额变更记录',
    //             },
    //             component: () => import('@/views/cardManagement/balanceManagement/balanceChangeRecord.vue')
    //         },
            
    //     ]
	//   },
	  {
        path: '/orderManagement',
        component:Layout,
        children: [{
                path: 'agentApplyCheckDetail',
                meta: {
                 isMenu:false,
                 title: '订单详情',
                },
                component: () => import('@/views/orderManagement/agentApplyDetail.vue')
            },
            
        ]
	  },
	  {
        path: '/historyRecord',
        component:Layout,
        children: [{
                path: 'networkCardFailList',
                meta: {
                  isMenu:false,
                  title: '网卡分销记录',
                },
                component: () => import('@/views/historyRecord/networkCardFailList.vue')
            },
            
        ]
	  },
	  {
        path: '/agentManagement',
        component:Layout,
        children: [{
                path: 'agentAnnounce',
                meta: {
                  isMenu:false,
                  title: '公告',
                },
                component: () => import('@/views/agentManagement/agentAnnounce.vue')
            },
            
        ]
	  },

	{
	  path: '/to',
	  component:Layout,
	  children: [{
	  		path: 'doc',
	  		meta: {
				isMenu:false,
	  			title: '',
	  		},
	  		component: () => import('@/views/doc/doc.vue')
	  	}
	  ]
	}
]
export const NotFoundRouterMap = [{
		path: '*',
		meta:{
			title:"404",
			isMenu:false
		},
		component: () => import('@/views/error/404.vue')
	},
]

let  routerMap = constantRouterMap.concat(router_wq).concat(LXL)
export default new Router({
	routes: routerMap
})

