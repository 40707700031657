import {Layout} from '@/layout'


let routers = [
                {
                    path: '/cardManagement',
                    component:Layout,
                    children: [
                        {
                            path: 'cardImportFailList',
                            meta: {
                            isMenu:true,
                            title: '导入失败数据',
                            },
                            component: () => import('@/views/cardManagement/cardImport/failList.vue')
                        },
                        {
                            path: 'cardBillDetail',
                            meta: {
                            isMenu:true,
                            title: '单卡卡务管理',
                            },
                            component: () => import('@/views/cardManagement/cardBillDetail.vue')
                        },
                        {
                            path: 'soloKawudailishang',
                            meta: {
                            isMenu:true,
                            title: '单卡卡务管理',
                            },
                            component: () => import('@/views/cardManagement/soloKawudailishang.vue')
                        },
                        {
                            path: 'cardBillDetailVip',
                            meta: {
                            isMenu:true,
                            title: '单卡卡务管理VIP',
                            },
                            component: () => import('@/views/cardManagement/cardBillDetailVip.vue')
                        },
                        {
                            path: 'tingfujiRecord',
                            meta: {
                            isMenu:true,
                            title: '停复机记录',
                            },
                            component: () => import('@/views/cardManagement/cardBillDetail/tingfujiRecord.vue')
                        },
                        {
                            path: 'tingfujiDetail',
                            meta: {
                            isMenu:true,
                            title: '停复机详情',
                            },
                            component: () => import('@/views/cardManagement/cardBillDetail/tingfujiDetail.vue')
                        },
                        {
                            path: 'orderRecord',
                            meta: {
                            isMenu:true,
                            title: '往期订单',
                            },
                            component: () => import('@/views/cardManagement/cardBillDetail/orderRecord.vue')
                        },
                        {
                            path: 'failList',
                            meta: {
                            isMenu:true,
                            title: '分销失败数据',
                            },
                            component: () => import('@/views/cardManagement/distributionCard/failList.vue')
                        },
                        {
                            path: 'batchCardFailList',
                            meta: {
                            isMenu:true,
                            title: '导入失败数据',
                            },
                            component: () => import('@/views/cardManagement/batchCard/failList.vue')
                        },
                        {
                            path: 'viewFail',
                            meta: {
                            isMenu:true,
                            title: '查看失败',
                            },
                            component: () => import('@/views/cardManagement/batchRechargeLog/viewFail.vue')
                        },
                        {
                            path: 'refundFailList',
                            meta: {
                            isMenu:true,
                            title: '导入失败数据',
                            },
                            component: () => import('@/views/cardManagement/refund/failList.vue')
                        },
                        {
                            path: 'whiteList',
                            meta: {
                            isMenu:true,
                            title: '白名单详情列表',
                            },
                            component: () => import('@/views/cardManagement/whiteList.vue')
                        }
                    ]
                },{
                    path: '/virtualCard',
                    component:Layout,
                    children: [
                        {
                            path: 'virtualCardDetail',
                            meta: {
                            isMenu:true,
                            title: '虚拟卡详情',
                            },
                            component: () => import('@/views/virtualCard/virtualCardDetail.vue')
                        },
                    ]
                },{
                    path: '/virtualCard',
                    component:Layout,
                    children: [
                        {
                            path: 'cardPropertySetting',
                            meta: {
                            isMenu:true,
                            title: '卡属性设置',
                            },
                            component: () => import('@/views/virtualCard/cardPropertySetting.vue')
                        },
                    ]
                }
            ]

export default routers