import request from './request.js'
export function login(params) {
    return request({
        url: 'pages/login3.do?',
        method: 'post',
        data: params
    })
}
export function getMenuList(params) {
    return request({
        url: 'pages/index.do?',
        method: 'post',
        data: params
    })
}
export function logout(params) {
    return request({
        url: '/pages/loginOut.do?',
        method: 'post',
        data: params
    })
}

export function getSmsCode(params){//修改密码获取验证码
    return request({
        url: '/agent/sendSmsCodePsw.do?',
        method: 'post',
        data: params
    })
}

export function modifyPsw(params){
    return request({
        url: '/agent/editPsw.do?',
        method: 'post',
        data: params
    })
}

export function getSmsCodeBindPhone(params){//修改密码获取验证码
    return request({
        url: '/agent/sendSmsCodeBundPhone.do?',
        method: 'post',
        data: params
    })
}

export function bindPhone(params){
    return request({
        url: '/agent/verifySmsCodLogin.do?',
        method: 'post',
        data: params
    })
}

export function getDictGroups(params) {  //获取字典列表
    return request({
        url: 'dict/qury.do?',
        method: 'post',
        data: params
    })
}
export function getNewCode(params){//修改密码获取验证码
    return request({
        url: '/pages/sendSmsCodePhone.do?',
        method: 'post',
        data: params
    })
}